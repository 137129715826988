export const artifact = {
  "en": {
    "artifact": "artifact",
    "artifacts": "artifacts",
    "cancelled": "cancelled",
    "completed": "completed",
    "date-end": "end date",
    "date-start": "start date",
    "name": "name",
    "notes": "notes",

  },
  "pt-br": {
    "artifact": "artefato",
    "artifacts": "artefatos",
    "cancelled": "cancelado",
    "completed": "concluído",
    "date-end": "data final",
    "date-start": "data inicial",
    "name": "nome",
    "notes": "notas",
  },
};
