<script setup></script>
<template>
  <hr class="actions-divider">
</template>

<style scoped>
.actions-divider {
  margin: 0;
  margin-block: var(--size-10);

  border: none;
  border-top: var(--size-00) solid var(--color-surface-70);
}
</style>
