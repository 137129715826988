export const pagePreferences = {
  "en": {
    locale: "locale",
    preferences: "preferences",
  },
  "pt-br": {
    locale: "idioma",
    preferences: "preferências",
  },
};
