export const frameSign = {
  "en": {
    "email": "email",
    "password": "password",
    "sign-in": "sing in",
    "sign-up": "sing up",
  },
  "pt-br": {
    "email": "email",
    "password": "senha",
    "sign-in": "entrar",
    "sign-up": "criar conta",
  },
};
